import React, { useEffect, useState } from 'react';
import { generatePath, Redirect, Route, useHistory } from 'react-router-dom';
import { IonApp, IonButton, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import SubmissionPage from './pages/SubmissionPage';
import LoginPage from './pages/LoginPage';
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import Axios from 'axios';
import axios from 'axios';
import { Storage } from '@ionic/storage';
import FeedbackHome from './pages/FeedbackHome';

var firebaseConfig = {
  apiKey: "AIzaSyDVhp0oRdYnaktSIGb0T7mee-uW4UDHtUA",
  authDomain: "ionic-d8e6c.firebaseapp.com",
  databaseURL: "https://ionic-d8e6c.firebaseio.com",
  projectId: "ionic-d8e6c",
  storageBucket: "ionic-d8e6c.appspot.com",
  messagingSenderId: "50738657836",
  appId: "1:50738657836:web:f75abe3a6fbd10ba28ef89",
  measurementId: "G-RB71EZ4R0B"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);



const App: React.FC = () => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [conferences, setConferences] = useState([])

  const [showModal, setShowModal] = useState(false);
  const [store, setStore] = useState<Storage | null>(null)
  const [currentConf, setCurrentConf] = useState<String | null>(null)


  async function getConferences() {
    return (await axios.get(`${process.env.REACT_APP_STRAPI_SERVER}/conferences`)).data
  }

  async function setUpStorage() {
    const store = new Storage({
      name: '__conference'
    });
    await store.create();
    setStore(store)
    const conf = await store.get('conference')
    console.log(conf)
    if (conf === null) {
      getConferences().then((data) => {
        setConferences(data)
      }).catch((err) => {
        console.log(err)
      })
      // setShowModal(true)
    } else {
      Axios.interceptors.request.use(async function (config) {
        // Do something before request is sent
        // console.dir(config)
        // if (config.method === "get" && !config.url?.includes("conferences")) {
        //   const conferenceId = conf
        //   console.log(conferenceId)
        //   config.params = {}
        //   config.params["conference.id"] = `${conferenceId}`
        // }

        return config;
      }, function (error) {
        // Do something with request error
        return Promise.reject(error);
      });
      setCurrentConf(conf)
    }

    return

  }

  useEffect(() => {
    async function getToken(user: firebase.User) {
      Axios.defaults.headers.common['Authorization'] = `Bearer ${await user.getIdToken()}`
    }


    firebase.auth().onAuthStateChanged((user) => {

      if (user != null) {
        getToken(user)
        setUser(user);


      }



    })

    setUpStorage()

  }, [showModal, currentConf])

  return (
    <IonApp>

      {/* <IonMenu menuId="help"  contentId="toolbar" type="overlay" side="end">
              <IonHeader>
                  <IonToolbar color="primary">
                  <IonTitle>Start Menu</IonTitle>
                  </IonToolbar>
              </IonHeader>
              <IonContent>
                  <IonList>
                  <IonItem>Menu Item</IonItem>
                  <IonItem>Menu Item</IonItem>
                  <IonItem>Menu Item</IonItem>
                  <IonItem>Menu Item</IonItem>
                  <IonItem>Menu Item</IonItem>
                  </IonList>
              </IonContent>
              </IonMenu> */}

      <IonModal isOpen={showModal}>
        <IonList>
          <IonListHeader>Select Conference</IonListHeader>
          {conferences.map((conference: any) => {
            return (
              <IonItem button onClick={async () => {
                await store?.set('conference', conference.id)
                Axios.interceptors.request.use(async function (config) {
                  // Do something before request is sent
                  // console.dir(config)
                  if (config.method === "get" && !config.url?.includes("conferences")) {
                    const conferenceId = conference.id
                    console.log(conferenceId)
                    config.params = {}
                    config.params["conference.id"] = `${conferenceId}`
                  }

                  return config;
                }, function (error) {
                  // Do something with request error
                  return Promise.reject(error);
                });
                setShowModal(false)
                setCurrentConf(conference.id)

              }}>
                <IonLabel>{conference?.name}</IonLabel>
              </IonItem>
            )
          })}
        </IonList>
        <IonButton onClick={() => setShowModal(false)}>Close Modal</IonButton>
      </IonModal>

      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/feedback/home" component={FeedbackHome} exact={true} />
          <Route path="/home" component={Home} exact={true} />
          <Route path="/submission/:id" component={SubmissionPage}></Route>
          <Route path="/login" component={LoginPage}></Route>
          <Route exact path="/" render={() => <Redirect to="/login" />} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
