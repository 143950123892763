import { IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonMenu, IonMenuButton, IonMenuToggle, IonTitle, IonToolbar } from "@ionic/react";
import React, { useState } from "react";
import { ellipsisVerticalOutline, menu, qrCodeOutline } from "ionicons/icons"
import { menuController } from '@ionic/core'

const HomeToolbar: React.FC = () => {
    const [showQrAlert, setShowQrAlert] = useState(false)

    function openMenu() {
        menuController.open("help")
    }

    return (
        <div>

            <IonAlert
                isOpen={showQrAlert}
                onDidDismiss={() => setShowQrAlert(false)}
                header={'UA/UAH Joint Doctoral Intensive 2023: Collaborating to Advance Health Equity'}
                message={'QR Code scanner is currently disabled.'}
                buttons={['OK']}
            />
            <IonToolbar>

                <IonButtons slot="start">
                    {/* <IonMenuButton autoHide={false}></IonMenuButton> */}
                </IonButtons>
                <IonTitle>UA/UAH Joint Doctoral Intensive 2023: Collaborating to Advance Health Equity</IonTitle>
                <IonButtons slot="start" >
                    <IonMenuButton autoHide={false} menu="help" onClick={openMenu}></IonMenuButton>
                </IonButtons>
            </IonToolbar>
        </div>
    )
}

export default HomeToolbar;