import { EmailComposer } from "@ionic-native/email-composer"
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonButtons, IonButton, IonIcon, IonLabel, isPlatform } from "@ionic/react"
import firebase from "firebase"
import { arrowBackOutline, helpOutline, mailOutline, personCircleOutline, phonePortraitOutline } from "ionicons/icons"
import React from "react"
import { useHistory } from "react-router"
import JudgeInterface from "./JudgeInterface"
import { menuController } from '@ionic/core'

const HomeMenu: React.FC<{ judge: JudgeInterface }> = ({ judge }) => {
    const history = useHistory()


    function emailAdmin() {

        if (!isPlatform('mobileweb')) {
            EmailComposer.open({}).then(() => {

            }).catch((err: any) => {
                console.error(err);
                alert(err)
            })
        }

        else {
            window.open('mailto:ndw0009@uah.edu')
        }

    }

    function signOut() {
        firebase.auth().signOut();
    }
    return (
        <IonMenu menuId="help" contentId="home" side="start">
            <IonHeader>
                <IonToolbar color="primary">
                    {/* <IonTitle>{judge.first_name} {judge.last_name?.split(' ')[0]} {judge.last_name?.split(' ')[1]}</IonTitle> */}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {/* <IonItem>
                        <IonButtons>
                            <IonButton>
                                <IonIcon src={phonePortraitOutline}></IonIcon>
                            </IonButton>
                            <IonLabel>Tutorial</IonLabel>
                        </IonButtons>
                    </IonItem> */}
                    <IonItem>
                        <IonButtons>
                            <IonButton>
                                <IonIcon src={helpOutline}></IonIcon>
                            </IonButton>
                            <IonLabel>Help</IonLabel>
                        </IonButtons>
                    </IonItem>
                    {/* <IonItem>
                        <IonButtons onClick={() => emailAdmin()}>
                            <IonButton>
                                <IonIcon src={mailOutline}></IonIcon>
                            </IonButton>
                            <IonLabel>Email Administator</IonLabel>
                        </IonButtons>
                    </IonItem> */}
                    <IonItem >
                        <IonButtons onClick={() => signOut()}>
                            <IonButton>
                                <IonIcon src={personCircleOutline}></IonIcon>
                            </IonButton>
                            <IonLabel>Sign Out</IonLabel>
                        </IonButtons>

                    </IonItem>
                </IonList>
                <IonItem >
                    <IonButtons onClick={() => { menuController.close("help") }}>
                        <IonButton>
                            <IonIcon src={arrowBackOutline}></IonIcon>
                        </IonButton>
                        <IonLabel>Back</IonLabel>
                    </IonButtons>
                </IonItem>
            </IonContent>
        </IonMenu>
    )
}

export default HomeMenu;