import { IonButton, IonContent, IonFab, IonHeader, IonItem, IonLabel, IonMenu, IonMenuToggle, IonRadio, IonRadioGroup, IonTitle, IonToolbar } from "@ionic/react"
import React from "react";

const FilterMenu: React.FC<{ setQueryFilter: any }> = ({ setQueryFilter }) => {
    return (
        <IonMenu contentId="home" menuId="departments-content" side="start" >
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Departments</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonRadioGroup onIonChange={(e) => {
                    var val: string = e.detail.value! as string
                    setQueryFilter(val.toUpperCase())
                }}>
                    <IonItem>
                        <IonLabel>AHSS- Arts, Humanities, Social Sciences</IonLabel>
                        <IonRadio slot="end" value="ahss"></IonRadio>
                    </IonItem>
                    <IonItem>
                        <IonLabel>COE - Engineering</IonLabel>
                        <IonRadio slot="end" value="coe"></IonRadio>
                    </IonItem>
                    <IonItem>
                        <IonLabel>COS - Science</IonLabel>
                        <IonRadio slot="end" value="cos"></IonRadio>
                    </IonItem>
                    <IonItem>
                        <IonLabel>COEd - Education</IonLabel>
                        <IonRadio slot="end" value="coed"></IonRadio>
                    </IonItem>
                </IonRadioGroup>
                <IonMenuToggle>
                    <IonButton>Save</IonButton>
                </IonMenuToggle>
            </IonContent>
        </IonMenu>
    )
}

export default FilterMenu;