import { IonMenu, IonHeader, IonTitle, IonContent, IonList, IonListHeader, IonItem, IonFooter, IonMenuButton, IonToolbar, IonButtons, IonButton, IonIcon, IonLabel } from "@ionic/react";
import React from "react"
import { menuController } from '@ionic/core'
import { arrowBackOutline } from "ionicons/icons";


const ScoreRubricMenu: React.FC<{ index: number, rubrics: string[] }> = ({ index, rubrics }) => {
    return (
        <IonMenu menuId={`rubric_${index}`} contentId={`submission_${index}`} side="start">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Scoring Rubric</IonTitle>
                </IonToolbar>

            </IonHeader>
            <IonContent>

                <IonList>

                    {rubrics.map((rubric, index) => {
                        return (
                            <IonItem>
                                {/* <p>5</p> */}
                                <p> {rubrics.length - index} - {rubric}</p>
                            </IonItem>
                        )
                    })}


                </IonList>
                <IonItem >
                    <IonButtons onClick={() => { menuController.close(`rubric_${index}`) }}>
                        <IonButton>
                            <IonIcon src={arrowBackOutline}></IonIcon>
                        </IonButton>
                        <IonLabel>Back to Scoring</IonLabel>
                    </IonButtons>
                </IonItem>
            </IonContent>

        </IonMenu>
    )
}

export default ScoreRubricMenu;
