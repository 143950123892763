import { IonCard, IonItem, IonCardTitle, IonLabel, IonText, IonCardHeader, IonCardSubtitle, IonGrid, IonRow, IonCol, IonButtons, IonButton, IonIcon, IonCardContent, isPlatform } from "@ionic/react";
import firebase from "firebase";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import React from "react";
import './PresenterCard.css'

//TODO: Create an interface for results
//TODO: Create modified interface for judge
//TODO: Create interface for data being passed in 
const PresenterCard: React.FC<{ data: any, results: any, judge: any }> = ({ data, results, judge }) => {
    function isCompleted(id: string): boolean {
        // console.log(results)
        for (let index = 0; index < results.length; index++) {
            const result = results[index];
            // console.log(index)
            // console.log(result.presenter)
            // console.log(id)
            // console.log(judge.id)
            // console.log(result.judge)
            // console.log(data.firstName)

            if (result.judge === judge.id && result.presenter === id) {
                return true;
            }
        }

        return false;
    }

    function labelColor(university: string) {
        if (university === "UAH") {
            return "university-label-blue"
        }

        else if (university === "UAB") {
            return "university-label-green"
        }

        else if (university === "UA") {
            return "university-label-red"
        }

        else {
            return ""
        }
    }

    return (
        isPlatform('desktop') || isPlatform('tablet') ? (<IonCard href={`/submission/${data.id}`} disabled={isCompleted(data.id)} class="boxes">
            <IonItem lines="none">
                <IonCardTitle class="text-size">{data.firstName} {data.lastName}</IonCardTitle>
                <IonLabel slot="end">
                    {firebase.auth().currentUser?.isAnonymous && <IonText>{data.department}</IonText>}
                    {!firebase.auth().currentUser?.isAnonymous && <IonText> Group {data.flight}</IonText>}

                </IonLabel>
            </IonItem>
            <IonCardHeader>
                {/* <IonItem lines="none">
            <IonLabel slot="end">Test</IonLabel>
        </IonItem> */}
                <IonCardSubtitle>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonLabel className={`${labelColor(data.university)} ion-padding-start ion-padding-end`}>{data.university}</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonButtons className="completed-button">
                                    <IonButton>
                                        <IonIcon size="small" src={isCompleted(data.id) ? checkmarkCircleOutline : closeCircleOutline}></IonIcon>
                                    </IonButton>
                                    <IonLabel class="completed-text-size">{isCompleted(data.id) ? "Completed" : "In Progress"}</IonLabel>
                                </IonButtons>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
                {data.projectTitle}
            </IonCardContent>

        </IonCard>) : (<IonCard href={`/submission/${data.id}`} disabled={isCompleted(data.id)} >
            <IonItem lines="none">
                <IonCardTitle>{data.firstName} {data.lastName}</IonCardTitle>
                <IonLabel slot="end">
                    {!firebase.auth().currentUser?.isAnonymous && <IonText> Group {data.flight}</IonText>}
                    {firebase.auth().currentUser?.isAnonymous && <IonText> Group {data.department}</IonText>}

                </IonLabel>
            </IonItem>
            <IonCardHeader>
                {/* <IonItem lines="none">
                <IonLabel slot="end">Test</IonLabel>
            </IonItem> */}
                <IonCardSubtitle>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonLabel className={`${labelColor(data.university)} ion-padding-start ion-padding-end`}>{data.university}</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonButtons className="completed-button">
                                    <IonButton>
                                        <IonIcon src={isCompleted(data.id) ? checkmarkCircleOutline : closeCircleOutline}></IonIcon>
                                    </IonButton>
                                    <IonLabel>{isCompleted(data.id) ? "Completed" : "In Progress"}</IonLabel>
                                </IonButtons>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
                {data.projectTitle}
            </IonCardContent>

        </IonCard>)

    )
}

export default PresenterCard;