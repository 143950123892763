import { IonAlert, IonButton, IonItem, IonLabel, IonNote, IonText } from "@ionic/react";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import CommentBox from "../components/CommentBox";
import PointCardSlider from "../components/PointCardSlider";
import { config } from "../config";
import { Drivers, Storage } from '@ionic/storage';
import firebase from "firebase";

const PointCardList: React.FC<{ questions: string[], judge: any, presenter: string }> = ({ questions, judge, presenter }) => {

    const [scores, setScores] = useState<number[]>([0, 0, 0, 0, 0])
    const [total, setTotal] = useState<number>(0);
    const [comment, setComment] = useState<string>("")
    const [showCommentModel, setShowCommentModel] = useState<boolean>(false)
    const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false)
    // const forceUpdate = React.useCallback(() => updateState({}), []);
    // const [, updateState] = React.useState();

    const storage = new Storage({
        name: `${presenter}_points`,
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    });

    const history = useHistory()

    function changeComment(comment: string) {
        setComment(comment);
        // console.log(comment)
    }

    function checkScores() {

        if (!firebase.auth().currentUser?.isAnonymous) {
            let zeroes: number[] = [];

            for (let index = 0; index < scores.length; index++) {
                const element = scores[index];

                if (element === 0) {
                    zeroes.push(index + 1)
                }
            }

            console.log(zeroes);

            if (zeroes.length > 0) {
                let errorString: string = ""

                for (let index = 0; index < zeroes.length; index++) {
                    const element = zeroes[index];
                    errorString = errorString + `Question ${element} is blank. \n`
                }

                alert(errorString)
            }

            else {

                if (comment === "") {
                    setShowCommentModel(true)
                }

                else {
                    setShowSubmitModal(true)
                }

            }
        } else {
            if (comment === "") {
                setShowCommentModel(true)
            }

            const nullScores = []
            submitScores()
        }



    }

    function submitScores() {
        const submissionObj = { judge_name: `${judge.first_name} ${judge.last_name}`, criteria_one: scores[0], criteria_two: scores[1], criteria_three: scores[2], criteria_four: scores[3], criteria_five: scores[4], criteria_six: scores[5], comment: comment, total_points: total, presenter: presenter, judge: judge.id }

        Axios.post(`${process.env.REACT_APP_STRAPI_SERVER}/research-results`, submissionObj).then((res) => {
            console.log(res.data);
            alert('Successfully submitted scores!')
            history.push("/")

        }).catch((err) => {
            console.log(err)
            alert(JSON.stringify(err))
        })
    }

    function updateScore(index: number, score: number) {
        let newScores: number[] = scores
        newScores[index] = score;
        // console.log(score)
        // console.log(newScores)
        setScores(newScores);
        storage.create().then((storage) => {
            storage.set(index.toString(), score)

        })

        setTotal(0)
        let amount: number = 0
        for (let index = 0; index < newScores.length; index++) {
            const score = newScores[index];
            amount = amount + score;
        }
        setTotal(amount)
    }

    useEffect(() => {
        // console.log('effect', scores)
        // setTotal(0)
        // for (let index = 0; index < scores.length; index++) {
        //     const score = scores[index];
        //     setTotal(total + score);
        // }
        storage.create().then((storage) => {
            storage.keys().then((keys) => {
                let newScores: number[] = scores
                for (let index = 0; index < keys.length; index++) {
                    const element = keys[index];
                    // console.log(element)
                    storage.get(element).then((val) => {
                        newScores[Number.parseInt(element)] = val
                        setScores(newScores)
                    })
                }

            })
        })
    },)

    return (
        <div>
            <IonAlert
                isOpen={showCommentModel}
                onDidDismiss={() => {
                    setShowCommentModel(false)
                    setShowSubmitModal(true)
                }}
                cssClass='my-custom-class'
                header={'Warning'}
                message={"It seems that you didn't leave a comment. Would you like to leave one?"}
                buttons={[{
                    text: 'Leave Comment',
                    handler: () => {
                        setShowCommentModel(false)
                    }
                }, {
                    text: 'No Comment',
                    handler: () => {
                        // submitScores()
                    }
                }]}
            />

            {!firebase.auth().currentUser?.isAnonymous && questions.map((question, index) => {
                return (
                    <PointCardSlider key={index} title={question} index={index} scores={scores} updatesScores={updateScore}></PointCardSlider>
                )
            })}

            <IonAlert
                isOpen={showSubmitModal}
                onDidDismiss={() => setShowSubmitModal(false)}
                cssClass='my-custom-class'
                header={'Warning'}
                message={`You are finalizing your scores! Have you reported your scores?<br/>Content & Coherence: <b>${scores[0]}</b><br/>Visual Presentation: <b>${scores[1]}</b><br/>Mechanics: <b>${scores[2]}</b><br/>Originality & Creativity: <b>${scores[3]}</b><br/>Oral Pres: <b>${scores[4]}</b><br/>Relevance: <b>${scores[5]}</b><br/>Total: <b>${total}</b>`}
                buttons={[{
                    text: 'Yes',
                    handler: () => {
                        submitScores()
                    }
                }, {
                    text: 'No',
                    handler: () => {
                        setShowCommentModel(false)

                    }
                }]}
            />

            <CommentBox changeComment={changeComment}></CommentBox>
            <IonItem lines="none">
                <IonNote>If you would like a student to be able to contact for questions, please leave your email in the comment box</IonNote>
            </IonItem>
            {!firebase.auth().currentUser?.isAnonymous && <IonItem>
                <IonText><h3>Total Score: </h3> </IonText>
                <IonText class="ion-padding-left"><h3>{total}</h3> </IonText>
            </IonItem>}

            <IonButton expand="block" onClick={() => { checkScores() }}>
                <IonLabel>
                    {!firebase.auth().currentUser?.isAnonymous ? "Submit Scores" : "Submit Feeback"}
                </IonLabel>
            </IonButton>
        </div>
    )
}

export default PointCardList;