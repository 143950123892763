import { IonAlert, IonToast, isPlatform, useIonViewDidEnter, useIonViewWillEnter, IonLoading } from "@ionic/react";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Presenter from "../components/PresenterInterface";
import {
    Plugins,
    PushNotification,
    PushNotificationToken
} from '@capacitor/core'
import { config } from "../config";
import "./Home.css"
import PresenterCardList from "../components/PresenterCardList";
import JudgeInterface from "../components/JudgeInterface";
import firebase from "firebase";
import { Redirect } from "react-router";
import { useAuthState } from 'react-firebase-hooks/auth';
import MyNotification from "../components/MyNotificationInterface";
import { handLeft } from "ionicons/icons";

const { PushNotifications } = Plugins;

const Home: React.FC = () => {
    const [presenters, setPresenters] = useState<Presenter[]>([])
    const [segment, setSegment] = useState<string>("poster")
    const [showNotificationToast, setShowNotificationToast] = useState<boolean>(false)
    const [results, setResults] = useState<any>([])
    const [showNotification, setShowNotification] = useState<boolean>(false)
    const [notification, setNotification] = useState<MyNotification>()
    const [judge, setJudge] = useState<any>({})
    const [authenticated,] = useAuthState(firebase.auth())
    const [showLoading, setShowLoading] = useState(true)

    async function fetchResults() {
        await Axios.get(`${process.env.REACT_APP_STRAPI_SERVER}/research-results`).then((res) => {
            setResults(res.data)
        }).catch((err) => {
            console.error(err);
            alert("Error fetching results")
        })
    }

    async function fetchJudge(email: string) {
        // var newJudge = {}
        try {
            console.log('fetching judges')
            const data: any = await (await Axios.get(`${process.env.REACT_APP_STRAPI_SERVER}/research-judges?email=${email}`)).data
            if (data && data[0] !== undefined) {
                let currentJudge: JudgeInterface = {
                    id: data[0].id,
                    email: data[0].email,
                    event_code: data[0].event_code,
                    first_name: data[0].first_name,
                    last_name: data[0].last_name,
                    flight: data[0].flight,
                    university: data[0].university,
                    device_token: data[0].device_token,
                    is_anonymous: false
                }


                var newJudge = currentJudge;
                newJudge.device_token = ""
                console.log(currentJudge)
                setJudge(currentJudge)
                setupNotifications(currentJudge)
                return Promise.resolve(currentJudge)
            }
        } catch (error) {
            console.log(error);
            alert('Error getting judge info.')
            return Promise.reject(error)
        }
    }

    function setupNotifications(judge: any) {
        if (isPlatform('capacitor')) {
            PushNotifications.requestPermission().then((result) => {
                if (result.granted) {
                    PushNotifications.register()
                    alert('Notificans succesfully registered.')
                }

                else {
                    alert("Could not register push notifications")
                }
            })

            PushNotifications.addListener('registration', (token: PushNotificationToken) => {
                console.log('token:' + token.value);
                console.log(judge)
                // alert('Push registration success, token: ' + token.value);
                // alert(judge.device_token)
                // alert(token.value)
                if (judge) {
                    if (judge.device_token == "" && judge.id != undefined) {
                        Axios.put(`${process.env.REACT_APP_STRAPI_SERVER}/research-judges/${judge.id}`, { device_token: token.value }).then((res) => {
                            // alert('succesfull')
                        }).catch((err) => {
                            console.error(err);
                            alert("test" + err);
                        })
                    }

                }


            })

            PushNotifications.addListener('registrationError', (error: any) => {
                alert('Error on notification registration: ' + JSON.stringify(error));
                setShowNotificationToast(true)
            })

            PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => {
                // alert('Push received: ' + JSON.stringify(notification));
                // alert(notification)
                let newNotification: MyNotification = {
                    title: notification.title,
                    message: notification.body,
                    subtitle: notification.subtitle
                }
                // alert('show')
                setNotification(newNotification)
                setShowNotification(true)
            })
        }
    }
    async function fetchPresenters(type: string, flight: string) {
        let allPresenters: Presenter[] = []
        await Axios.get(`${process.env.REACT_APP_STRAPI_SERVER}/research-presenters?presentation_type=${type}&checked_in_eq=true&flight=${flight}`).then((response) => {
            let data: [] = response.data;
            for (let index = 0; index < data.length; index++) {
                const element: any = data[index];
                let presenter: Presenter = {
                    id: element.id,
                    firstName: element.first_name,
                    lastName: element.last_name,
                    email: element.email,
                    department: element.department,
                    projectTitle: element.project_title,
                    university: element.university,
                    presentationType: element.presentation_type,
                    flight: element.flight,
                    poster: "",
                    abstract: ""
                }
                allPresenters.push(presenter);
            }


        }).catch((err) => {
            console.error(err);
        })

        setPresenters(allPresenters);
    }


    useIonViewWillEnter(() => {


        if (authenticated) {
            setShowLoading(true)
            fetchJudge(firebase.auth().currentUser?.email!).then((judge) => {
                console.log(judge)
                setJudge(judge)
                fetchPresenters(segment, judge!["flight"])
                fetchResults()

            }).catch((err) => {
                console.log(err)
            })
        }

    }, [])

    if (authenticated) {

        return (
            isPlatform('tablet') || isPlatform('desktop') ?
                (<div>
                    <IonAlert isOpen={showNotification}
                        onDidDismiss={() => setShowNotification(false)}
                        header={notification?.title}
                        subHeader={notification?.subtitle}
                        message={notification?.message}
                        buttons={['OK']} />
                    <IonToast
                        isOpen={showNotificationToast}
                        onDidDismiss={() => setShowNotificationToast(false)}
                        message="There was an erroy registering for push notifications."
                        duration={200}
                    />
                    <IonLoading isOpen={showLoading} message={'Loading presentations'} duration={2000} />
                    <PresenterCardList segment={segment} presenters={presenters} fetchPresenters={fetchPresenters} setSegment={setSegment} results={results} judge={judge}></PresenterCardList>
                </div>) :
                (<div>
                    <IonAlert isOpen={showNotification}
                        onDidDismiss={() => setShowNotification(false)}
                        header={notification?.title}
                        subHeader={notification?.subtitle}
                        message={notification?.message}
                        buttons={['OK']} />
                    <IonToast
                        isOpen={showNotificationToast}
                        onDidDismiss={() => setShowNotificationToast(false)}
                        message="There was an erroy registering for push notifications."
                        duration={200}
                    />
                    <IonLoading isOpen={showLoading} message={'Loading presentations'} duration={2000} />
                    <PresenterCardList segment={segment} presenters={presenters} fetchPresenters={fetchPresenters} setSegment={setSegment} results={results} judge={judge}></PresenterCardList>
                </div>)

        )
    }

    else {
        return (
            <Redirect to="/login"></Redirect>
        )

    }


}

export default Home;