import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonTextarea } from "@ionic/react"
import React from "react"

const CommentBox: React.FC<{ changeComment: (comment: string) => void }> = ({ changeComment }) => {
    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>Comments</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonTextarea placeholder="Enter comments here...." onIonChange={(e) => changeComment(e.detail.value!)}></IonTextarea>
            </IonCardContent>
        </IonCard>
    )
}

export default CommentBox;